<template>
  <div>
    <b-row class="mb-2">
      <b-col lg="4">
        <label>Filtrar por contrato</label>
        <v-select  class="align-middle inline-block" v-model="contractSelected" :options="contractOptions" :reduce="v => v.value" :clearable="false"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-1 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMeters"
                statistic-title="Total de tarjetas de escalamiento"
                color="info"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMetersInstalled"
                statistic-title="Total de tarjetas de escalamiento (Instaladas)"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMetersNotInstalled"
                statistic-title="Total de tarjetas de escalamiento (No Instaladas)"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="metersTransmitting"
                statistic-title="Medidores que transmitieron antes de 24 horas"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="installedTransmittingMeters"
                statistic-title="Medidores instalados que transmitieron antes de 24 horas"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="RadioIcon"
                :statistic="installedTransmittingMeters"
                statistic-title="Medidores instalados transmitiendo a SiNaMed"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiOffIcon"
                :statistic="installedNotTransmittingMeters"
                statistic-title="Medidores instalados que transmitieron después de 24 horas"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por día por zona</b></div>
          <vue-apex-charts
            v-if="metersByDayChart.ready"
            type="bar"
            height="400"
            :options="metersByDayChart.chartOptions"
            :series="metersByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por semana por zona</b></div>
          <vue-apex-charts
            v-if="metersByWeekChart.ready"
            type="bar"
            height="400"
            :options="metersByWeekChart.chartOptions"
            :series="metersByWeekChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center">
            <b>Medidores por agencias</b>
            <b-col md="12" class="text-center d-flex justify-content-end">
              <b-button
                v-if="urlDownload.length > 0 && responseData.length > 0"
                :href="urlDownload"
                download="Medidores_conteo.csv"
                variant="outline-primarySpore">
                Descargar CSV
              </b-button>
            </b-col>
          </div>
          <vue-apex-charts
            v-if="agenciesChart.ready"
            type="bar"
            height="400"
            :options="agenciesChart.chartOptions"
            :series="agenciesChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por agencias por día</b></div>
          <vue-apex-charts
            v-if="agenciasByDayChart.ready"
            type="bar"
            height="400"
            :options="agenciasByDayChart.chartOptions"
            :series="agenciasByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por agencias por semana</b></div>
          <vue-apex-charts
            v-if="agenciasByWeekChart.ready"
            type="bar"
            height="400"
            :options="agenciasByWeekChart.chartOptions"
            :series="agenciasByWeekChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por cuadrilla</b></div>
          <vue-apex-charts
            v-if="teamsChart.ready"
            type="bar"
            height="400"
            :options="teamsChart.chartOptions"
            :series="teamsChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por cuadrillas por día</b></div>
          <vue-apex-charts
            v-if="teamsByDayChart.ready"
            type="bar"
            height="400"
            :options="teamsByDayChart.chartOptions"
            :series="teamsByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Medidores por cuadrillas por semana</b></div>
          <vue-apex-charts
            v-if="teamsByWeekChart.ready"
            type="bar"
            height="400"
            :options="teamsByWeekChart.chartOptions"
            :series="teamsByWeekChart.series"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { empty, timeZoneDate } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import store from '@/store'

const divisionUuid = store.state.session.AppActiveUser().division.uuid

export default {
  components: {
    BCard,
    VueApexCharts,
    BRow,
    BCol,
    StatisticCardVertical,
    BButton
  },
  data () {
    return {
      urlDownload: '',
      responseData: [],
      totalMeters: 0,
      totalTransmitting: 0,
      responseMetering: [],
      tableColumnsMetering: [
        { key: 'Number', label: 'Número Medidor', sortable: true },
        { key: 'total', label: 'Número Repetido', sortable: true }
      ],
      teamsChart: {
        ready: false,
        series: [
          {
            name: 'Medidores',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Cuadrillas'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      agenciesChart: {
        ready: false,
        series: [
          {
            name: 'Medidores',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Agencias'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      metersByWeekChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Semanas'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      metersByDayChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      agenciasByDayChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      teamsByDayChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      agenciasByWeekChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Semanas'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      teamsByWeekChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Semanas'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      donutsChart: [],
      rangePicker: [],
      totalMetersInstalled: 0,
      totalMetersNotInstalled: 0,
      installedTransmittingMeters: 0,
      installedNotTransmittingMeters: 0,
      metersTransmitting: 0,
      contractSelected: '',
      contractOptions: [
        { label: 'Todos', value: '' }
      ]
    }
  },
  methods: {
    async getDashboard () {
      const params = {
        url: 'report/deployment/079',
        method: 'GET',
        params: {
          divisionUuid,
          contract: this.contractSelected
        }
      }
      await request(params).then(response => {
        if (!empty(response.data.general)) {
          this.totalMeters = response.data.general.total[0].total ?? 0
          this.metersByDayChart.series = []
          this.metersByDayChart.chartOptions.xaxis.categories = []
          response.data.general.byDay.data.forEach((element, index) => {
            this.metersByDayChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.general.byDay.days.forEach((element, index) => {
            if (!element) {
              response.data.general.byDay.days[index] = 'N/INSTALADOS'
            } else {
              response.data.general.byDay.days[index] = timeZoneDate(element + ' 23:00:00')
            }
          })
          this.metersByDayChart.chartOptions.xaxis.categories = response.data.general.byDay.days
          this.metersByDayChart.ready = true

          this.metersByWeekChart.series = []
          this.metersByWeekChart.chartOptions.xaxis.categories = []
          response.data.general.byWeek.data.forEach((element, index) => {
            this.metersByWeekChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.general.byWeek.days.forEach((element, index) => {
            response.data.general.byWeek.days[index] = element ? element.toString() : 'N/INSTALADOS'
          })
          this.metersByWeekChart.chartOptions.xaxis.categories = response.data.general.byWeek.days
          this.metersByWeekChart.ready = true
        }

        if (!empty(response.data.agencies)) {
          this.agenciesChart.series[0].data = []
          this.responseData = []
          this.agenciesChart.chartOptions.xaxis.categories = []
          this.responseData = response.data.agencies.agenciesTotal
          response.data.agencies.agenciesTotal.forEach((element, index) => {
            this.agenciesChart.series[0].data.push({
              x: element.name,
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.agenciesChart.ready = true

          this.agenciasByDayChart.series = []
          this.agenciasByDayChart.chartOptions.xaxis.categories = []
          response.data.agencies.agenciesByDay.data.forEach((element, index) => {
            this.agenciasByDayChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.agencies.agenciesByDay.days.forEach((element, index) => {
            if (!element) {
              response.data.agencies.agenciesByDay.days[index] = 'N/INSTALADOS'
            } else {
              response.data.agencies.agenciesByDay.days[index] = timeZoneDate(element + ' 23:00:00')
            }
          })
          this.agenciasByDayChart.chartOptions.xaxis.categories = response.data.agencies.agenciesByDay.days
          this.agenciasByDayChart.ready = true

          this.agenciasByWeekChart.series = []
          this.agenciasByWeekChart.chartOptions.xaxis.categories = []
          response.data.agencies.arrayGraphagenciesByWeek.data.forEach((element, index) => {
            this.agenciasByWeekChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.agencies.arrayGraphagenciesByWeek.days.forEach((element, index) => {
            response.data.agencies.arrayGraphagenciesByWeek.days[index] = Number(element) ? element.toString() : 'N/INSTALADOS'
          })
          this.agenciasByWeekChart.chartOptions.xaxis.categories = response.data.agencies.arrayGraphagenciesByWeek.days
          this.agenciasByWeekChart.ready = true
        }

        if (!empty(response.data.teams)) {
          this.teamsChart.series[0].data = []
          this.teamsChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsTotal.forEach((element, index) => {
            this.teamsChart.series[0].data.push({
              x: !empty(element.name) ? element.name : 'S/N',
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.teamsChart.ready = true

          this.teamsByDayChart.series = []
          this.teamsByDayChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsByDay.data.forEach((element, index) => {
            this.teamsByDayChart.series.push({
              name: !empty(element.name) ? element.name : 'S/N',
              data: element.data
            })
          })
          response.data.teams.teamsByDay.days.forEach((element, index) => {
            response.data.teams.teamsByDay.days[index] = timeZoneDate(element + ' 23:00:00')
          })
          this.teamsByDayChart.chartOptions.xaxis.categories = response.data.teams.teamsByDay.days
          this.teamsByDayChart.ready = true

          this.teamsByWeekChart.series = []
          this.teamsByWeekChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsByWeek.data.forEach((element, index) => {
            this.teamsByWeekChart.series.push({
              name: !empty(element.name) ? element.name : 'S/N',
              data: element.data
            })
          })
          response.data.teams.teamsByWeek.days.forEach((element, index) => {
            response.data.teams.teamsByWeek.days[index] = element.toString()
          })
          this.teamsByWeekChart.chartOptions.xaxis.categories = response.data.teams.teamsByWeek.days
          this.teamsByWeekChart.ready = true
        }

        if (!empty(response.data.metersInfo)) {
          this.totalTransmitting = response.data.metersInfo.meters[0].total
          this.responseMetering = response.data.metersInfo.repeated
        }
        if (!empty(response.data.metersInfo)) {
          this.totalMeters = response.data.metersInfo.meters[0].total
          this.totalMetersInstalled = response.data.metersInfo.meters[0].installed ?? 0
          this.totalMetersNotInstalled = response.data.metersInfo.meters[0].notInstalled ?? 0
          this.transmittingMeters = response.data.metersInfo.meters[0].transmitting ?? 0
          this.NoTransmitingMeters = response.data.metersInfo.meters[0].no_transmitting ?? 0
          this.distincMeters = response.data.metersInfo.meters[0].distinct_numbers
          this.installedTransmittingMeters = response.data.metersInfo.meters[0].installedTransmitting ?? 0
          this.installedNotTransmittingMeters = response.data.metersInfo.meters[0].installedNotTransmitting ?? 0
          this.metersTransmitting = response.data.metersInfo.meters[0].metersTransmitting ?? 0
        }
        this.createCsv()
      })
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Agencia')
      headers.push('Total Gabinetes')
      csv.push(headers.join(','))
      this.responseData.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.total)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    },
    async getContractList () {
      const params = {
        url: '/contracts',
        method: 'GET',
        params: { divisionUuid }
      }
      await request(params).then(response => {
        this.contractOptions = [{ label: 'Todos', value: '' }]
        response.data.forEach(item => {
          if (!item.contract) return
          this.contractOptions.push({
            label: `Contrato ${item.contract}`,
            value: item.contract
          })
        })
      }).catch(err => {
        console.log(err)
        this.contractOptions = [{ label: 'Todos', value: '' }]
      })
    }
  },
  created () {
    this.getDashboard()
    this.getContractList()
  },
  watch: {
    contractSelected () {
      this.getDashboard()
    }
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
